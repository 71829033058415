import React from 'react'
import Link from '../utils/link'
import {Darling} from "./icons";

const Footer = () => {

  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <h6>A venue by</h6>
        <Link to='https://darlinggroup.com.au/' target='_blank' rel='noopener noreferrer'>
          <Darling color='#FF0500'/>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
