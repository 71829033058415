import React from 'react'
import {graphql} from "gatsby";

const Landing = ({desktopVideoUrl, mobileVideoUrl}) => {
  return (
    <section className='landing'>
      <div className='landing__inner'>
        {desktopVideoUrl && <video className='desktop' src={desktopVideoUrl} autoPlay loop muted playsInline/>}
        {mobileVideoUrl && <video className='mobile' src={mobileVideoUrl} autoPlay loop muted playsInline/>}
      </div>
    </section>
  )
}

export default Landing

export const query = graphql`
  fragment LandingProps on DatoCmsLanding {
    ... on DatoCmsLanding {
        id
        desktopVideoUrl
        mobileVideoUrl
      }
  }
`