import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import {graphql} from "gatsby";
import Link from "../utils/link";
import StructuredText from './custom-structured-text-renderer';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {

  state = {
    result: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("https://hooks.zapier.com/hooks/catch/3298056/3gw6gsk/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'subscribe',
      className: 'form',
      onSubmit: this.handleSubmit,
    }

    if (this.state.result === 'success')
      return (
        <div className='contact__success'>
          <h4>Thanks for subscribing.</h4>
        </div>
      )

    return (
      <form {...props}>
        <label htmlFor="subscribe">Subscribe</label>
        <div>
          <input placeholder='Email Address' name='email' type="email" onChange={(e) => this.handleChange(e)} />
          <button type='submit'>Subscribe</button>
        </div>
      </form>
    )
  }
}

const Contact = ({email, phone, instagram, hours, address}) => {

  return (
    <section className='contact'>
      <Fade distance='40px' bottom>
        <div className='contact__inner'>
          <div className='contact__wrapper'>
            <div className='contact__hours'>
              {hours?.value && <StructuredText data={hours}/>}
            </div>

            <div className='contact__address'>
              {address?.value && <StructuredText data={address}/>}
              {phone && <Link to={`tel:${phone}`}>{phone}</Link>}
              {email && <Link to={`mailto:${email}`}>{email}</Link>}
              {instagram && <Link to={`${instagram}`}>Instagram</Link>}
            </div>

            <div className='contact__subscribe'>
              <ContactForm />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default Contact

export const query = graphql`
fragment ContactProps on DatoCmsContact {
  ... on DatoCmsContact {
    id
    email
    phone
    instagram
    hours {
      value
    }
    address {
      value
    }
  }
}
`