/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import React from 'react';
import Image from '../components/image'
import Landing from "../components/landing";
import Content from "../components/content";
import Gallery from "../components/gallery";
import Contact from "../components/contact";
import EmptyBlock from '../components/empty-block';

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

export const RenderBlock = ({ __typename, ...props }) => {
    switch (__typename.replace('DatoCms', '')) {
        case 'Image':
            return <Image {...props} />;
        case 'Landing':
            return <Landing {...props} />;
        case 'Content':
            return <Content {...props} />;
        case 'Gallery':
            return <Gallery {...props} />;
        case 'Contact':
            return <Contact {...props} />;
        default:
            return <EmptyBlock>{__typename}</EmptyBlock>;
    }
};

const BlockRenderer = ({ data, className }) => {
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data && data.map((block) => {
                const { __typename: blockType } = block;

                if (!blockType) {
                    throw new Error("You must source __typename on every blocks field.")
                }

                return (
                    <RenderBlock
                        key={block.id}
                        className={className}
                        {...block}
                    />
                );
            })}
        </BlockRendererContext.Provider>
    );
};

export default BlockRenderer;
