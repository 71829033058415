/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import {
  isParagraph, isHeading, isList, isListItem, isLink, isBlockquote,
} from 'datocms-structured-text-utils';
import React from 'react';
import { renderNodeRule, StructuredText as StructuredTextRenderer, StructuredTextDocument } from 'react-datocms';
import clsx from 'clsx';
import BlockRenderer from '../utils/block-renderer';
import Link from '../utils/link';

const createParagraphRenderer = className => ({ children, key, ancestors }) => {
  // If the paragraph is inside a list item, simply output the text
  if ((ancestors[0] && isListItem(ancestors[0])) || (ancestors[0] && isBlockquote(ancestors[0]))) {
      return children;
  }

  return <p className={className} key={key}>{children}</p>;
};

const createLinkRenderer = (className, isBlog) => ({ node, children, key }) => {

  if (isBlog) {
      return (
          <Link key={key} to={node.url} className={className}>
              {children}
          </Link>
      )
  }

  return (
      <Link
          className={className}
          to={node.url}
          key={key}
      >
          {children}
      </Link>
  );
}

const createListRenderer = className => ({ children, key }) => (
  <ul
      key={key}
      className={className}
  >
      {children}
  </ul>
);

const createListItemRenderer = className => ({ children, key }) => (
  <li className={className} key={key}>{children}</li>
);

const createBlockquoteRenderer = className => ({ node, children, key }) => (
  <blockquote
      cite={node.attribution}
      key={key}
      className={className}
  >
      <p>{children}</p>
      <footer>
          <cite>{node.attribution}</cite>
      </footer>
  </blockquote>
);

const createHeadingRenderer = className => ({ node, children, key }) => {
  if (node.level === 1) {
      return (
          <h1 className={className} key={key}>{children}</h1>
      );
  }
  if (node.level === 2) {
      return (
          <h2 className={className} key={key}>{children}</h2>
      );
  }
  if (node.level === 3) {
      return (
          <h3 className={className} key={key}>{children}</h3>
      );
  }
  if (node.level === 4) {
      return (
          <h4 className={className} key={key}>{children}</h4>
      );
  }
  if (node.level === 5) {
      return (
          <h5 className={className} key={key}>{children}</h5>
      );
  }
  return (
      <h6 className={className} key={key}>{children}</h6>
  );
};

const createGenericRenderer = (as) => ({ children }) => {
  const Component = as;

  return  (
      <Component>
          {children}
      </Component>
  );
}

const StructuredText = ({
  data,
  className,
  isBlog,
  as,
}) => {
  if (!data) return null;
  if (!data?.value) return null;
  const updatedData = {
      ...data,
      blocks: (data.blocks || []).map(block => ({
          ...block,
          id: block.originalId,
      })),
  };
  return (
      <div
          className={clsx({
              wysiwyg: isBlog,
          })}
      >
          <StructuredTextRenderer
              data={updatedData}
              renderBlock={({ record }) => (
                  <BlockRenderer isBlog={isBlog} data={[record]} />
              )}
              customNodeRules={[
                  renderNodeRule(
                      isParagraph,
                      as ? createGenericRenderer(as || 'div') : createParagraphRenderer(className),
                  ),
                  renderNodeRule(
                      isLink,
                      createLinkRenderer(className, isBlog),
                  ),
                  renderNodeRule(
                      isList,
                      createListRenderer(className),
                  ),
                  renderNodeRule(
                      isListItem,
                      createListItemRenderer(className),
                  ),
                  renderNodeRule(
                      isBlockquote,
                      createBlockquoteRenderer(className),
                  ),
                  renderNodeRule(
                      isHeading,
                      as ? createGenericRenderer(as || 'div') : createHeadingRenderer(className),
                  ),
              ]}
          />
      </div>
  );
};

export default StructuredText;
