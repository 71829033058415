import React from 'react'

export const Logo = () => (
  <svg width="106" height="97" viewBox="0 0 106 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.873 23.152H74.4245V5.82195C74.4245 1.87456 74.132 0.771617 71.7917 0.137535C69.3074 -0.536736 67.3767 1.37444 65.6935 3.55354L50.4323 23.1565H14.3473C10.4678 23.1565 9.34272 23.6745 8.61814 25.7688C7.79455 28.1399 9.86478 30.1493 12.061 31.8149L31.8182 46.9569L0.818774 86.8773C-0.355857 88.3911 -0.256846 90.5255 1.0528 91.9321C1.82239 92.7582 2.8845 93.1914 3.95562 93.1914C4.70271 93.1914 5.45429 92.9815 6.11586 92.5528L43.641 69.6142L67.1157 95.4419C67.9123 96.4154 69.0959 96.9467 70.311 96.9467C70.7746 96.9467 71.2381 96.8708 71.6927 96.7101C73.3354 96.1296 74.429 94.589 74.429 92.8609V79.7685L95.8514 96.1876C97.377 97.3531 99.5283 97.2548 100.946 95.9554C101.779 95.1918 102.215 94.138 102.215 93.0753C102.215 92.334 102.004 91.5883 101.572 90.9319L78.4524 53.6996L104.483 30.4083C105.464 29.6179 106 28.4435 106 27.2379C106 26.1081 105.302 23.152 101.882 23.152H101.873ZM69.3344 42.5987H55.9004V54.9633H69.3344V67.3279H55.9004C55.8104 67.3279 55.7294 67.319 55.6394 67.3145C49.0372 67.1806 43.6996 61.9784 43.407 55.4635C43.398 55.2759 43.38 55.0928 43.38 54.9053V42.5987H38.5195V31.0245H69.3299V42.5987H69.3344Z"
      fill="#FFEFBB"/>
  </svg>
)

export const Darling = ({color}) => (
  <svg width="99" height="39" viewBox="0 0 99 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="a">
      <path id="Vector"
            d="M88.9038 23.0789C87.919 22.1399 86.5398 21.666 84.8056 21.666H78.4834V38.7192H81.0386V31.8683H84.6435C86.4715 31.8683 87.8985 31.4014 88.8919 30.4795C89.8853 29.5559 90.3888 28.2981 90.3888 26.7439C90.3888 25.1897 89.8887 24.0163 88.9038 23.0789ZM87.7158 26.7439C87.7158 27.5692 87.4427 28.2361 86.9068 28.7272C86.3657 29.2234 85.5959 29.4732 84.6196 29.4732H81.0386V24.0594H84.7118C85.6881 24.0594 86.4408 24.3023 86.9512 24.7813C87.4598 25.2569 87.7158 25.9168 87.7158 26.7439Z"
            fill={color}/>
      <path id="Vector_2" d="M57.7877 14.9389V17.3323H48.3282V0.277344H50.8851V14.9389H57.7877Z" fill="#FF0500"/>
      <path id="Vector_3" d="M62.8895 0.277344H60.3344V17.3323H62.8895V0.277344Z" fill="#FF0500"/>
      <path id="Vector_4"
            d="M98.9982 8.3655V17.3341H96.6479V15.1044C96.1102 15.8436 95.4189 16.4363 94.5809 16.8654C93.6114 17.3616 92.4968 17.6132 91.2644 17.6132C88.868 17.6132 86.8232 16.7689 85.188 15.1027C83.5545 13.4365 82.7233 11.3188 82.7233 8.8066C82.7233 6.29437 83.5579 4.17672 85.1999 2.51051C86.8419 0.844303 88.8919 0 91.2866 0C94.3863 0 96.7554 1.32332 98.3292 3.93549L98.3974 4.04921L96.2109 5.45351L96.1375 5.33979C94.9393 3.44786 93.3058 2.48811 91.2883 2.48811C89.6275 2.48811 88.2194 3.09291 87.1048 4.28527C85.9868 5.48108 85.4218 7.00255 85.4218 8.8066C85.4218 10.6107 85.9953 12.1321 87.127 13.3279C88.2569 14.5203 89.7129 15.1268 91.4522 15.1268C92.8723 15.1268 94.062 14.7064 94.9871 13.8793C95.8764 13.0833 96.3987 12.027 96.5438 10.7399H90.2557V8.36722H99L98.9982 8.3655Z"
            fill={color}/>
      <path id="Vector_5"
            d="M41.8933 9.62005C44.0952 8.82744 45.2132 7.33354 45.2132 5.17798C45.2132 3.72716 44.737 2.53652 43.7965 1.63535C42.856 0.73591 41.5298 0.279297 39.8519 0.279297H33.1388V17.3601H35.6991V10.0767H39.3074L42.8679 17.3601H45.8345L41.895 9.62005H41.8933ZM42.5368 5.17798C42.5368 5.94302 42.2842 6.55644 41.7858 7.00099C41.2823 7.45071 40.5466 7.67988 39.5976 7.67988H35.6974V2.67436H39.7597C40.661 2.67436 41.3557 2.89836 41.8302 3.33947C42.3064 3.78402 42.5368 4.38537 42.5368 5.17798Z"
            fill={color}/>
      <path id="Vector_6"
            d="M24.3673 0.33713L24.3315 0.250977H21.9828L15.1315 17.3042H17.8488L19.4652 13.1792H26.8491L28.4638 17.3042H31.1828L24.3673 0.33713ZM23.1589 3.96591L25.9564 10.8099H20.363L23.1606 3.96591H23.1589Z"
            fill={color}/>
      <path id="Vector_7"
            d="M57.0828 23.8852C55.4476 22.2276 53.3959 21.3867 50.9841 21.3867C48.5723 21.3867 46.5207 22.2276 44.8855 23.8852C43.252 25.5428 42.4208 27.6656 42.4208 30.1933C42.4208 32.7211 43.2503 34.8422 44.8855 36.4998C46.5207 38.1573 48.5723 38.9999 50.9841 38.9999C53.3959 38.9999 55.41 38.1332 57.0606 36.4291C58.7094 34.7233 59.5458 32.6263 59.5458 30.1916C59.5458 27.7569 58.7179 25.541 57.0828 23.8834M50.9841 36.5101C49.3216 36.5101 47.9101 35.9001 46.7869 34.6991C45.6621 33.4964 45.0937 31.9801 45.0937 30.1899C45.0937 28.3996 45.6638 26.8644 46.7869 25.6685C47.9101 24.4745 49.3216 23.8714 50.9841 23.8714C52.6466 23.8714 54.0565 24.4796 55.1779 25.6806C56.3027 26.885 56.8745 28.4013 56.8745 30.1916C56.8745 31.9819 56.2959 33.4206 55.1557 34.6543C54.0172 35.8863 52.6142 36.5101 50.9841 36.5101Z"
            fill={color}/>
      <path id="Vector_8"
            d="M75.0595 21.6658V32.5694C75.0595 34.5716 74.4946 36.1602 73.3817 37.2923C72.2671 38.4243 70.6729 38.9999 68.6434 38.9999C66.6139 38.9999 65.1375 38.414 63.9768 37.2578C62.8179 36.1034 62.229 34.494 62.229 32.4763V21.6641H64.7859V32.3591C64.7859 33.7135 65.1426 34.7542 65.8424 35.4555C66.544 36.1551 67.5169 36.5117 68.7373 36.5117C69.9577 36.5117 70.9596 36.1671 71.6321 35.4917C72.3029 34.8145 72.6426 33.8151 72.6426 32.5228V21.6641H75.0612L75.0595 21.6658Z"
            fill={color}/>
      <path id="Vector_9"
            d="M79.9053 0.277344V17.3323H77.2972L77.2562 17.272L68.8704 4.77455V17.3323H66.4501V0.277344H68.9677L69.007 0.337651L77.4867 12.9522V0.277344H79.9053Z"
            fill={color}/>
      <path id="Vector_10"
            d="M36.7881 30.9384C38.9917 30.1458 40.1079 28.6519 40.1079 26.4963C40.1079 25.0455 39.6317 23.8549 38.693 22.952C37.7525 22.0543 36.4245 21.5977 34.7484 21.5977H28.0336V38.6802H30.5939V31.3967H34.2005L37.7627 38.6802H40.7275L36.7881 30.9401V30.9384ZM37.4333 26.4963C37.4333 27.2614 37.179 27.8748 36.6806 28.3193C36.1787 28.7691 35.4414 28.9982 34.4924 28.9982H30.5939V23.9944H34.6562C35.5557 23.9944 36.2521 24.2184 36.7249 24.6595C37.2012 25.1041 37.4333 25.7055 37.4333 26.4981"
            fill={color}/>
      <path id="Vector_11"
            d="M12.6547 2.63278C11.1032 1.09064 9.02762 0.306641 6.48951 0.306641H0V17.3909H6.34955C8.92009 17.3909 11.0264 16.581 12.6086 14.9803C14.1926 13.3813 14.9948 11.3188 14.9948 8.84789C14.9948 6.37701 14.2063 4.17838 12.653 2.63278M12.3424 8.84789C12.3424 10.6709 11.7876 12.1665 10.6935 13.2951C9.59942 14.422 8.09226 14.9924 6.21129 14.9924H2.5603V2.70343H6.39563C8.19979 2.70343 9.65916 3.26343 10.7294 4.36791C11.7996 5.47068 12.3441 6.98008 12.3441 8.84789"
            fill={color}/>
      <path id="Vector_12"
            d="M24.8042 29.6585V38.6253H22.4555V36.3957C21.9196 37.1366 21.2266 37.7276 20.3902 38.1566C19.4224 38.6529 18.3061 38.9062 17.0738 38.9062C14.6773 38.9062 12.6325 38.0619 10.9973 36.3957C9.36384 34.7295 8.5343 32.6118 8.5343 30.0996C8.5343 27.5873 9.36725 25.4697 11.0093 23.8035C12.6513 22.1373 14.6995 21.293 17.0959 21.293C20.1956 21.293 22.5647 22.618 24.1385 25.2285L24.2068 25.3422L22.0203 26.7465L21.9469 26.6328C20.7486 24.7408 19.1152 23.7811 17.0977 23.7811C15.4352 23.7811 14.0287 24.3859 12.9141 25.58C11.7961 26.7758 11.2312 28.2972 11.2312 30.1013C11.2312 31.9053 11.8047 33.4268 12.9363 34.6226C14.0663 35.815 15.5222 36.4198 17.2598 36.4198C18.6816 36.4198 19.8713 36.0011 20.7947 35.174C21.684 34.3779 22.2063 33.32 22.3514 32.0329H16.0633V29.6619H24.8059L24.8042 29.6585Z"
            fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_228_97">
        <rect width="99" height="39" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
