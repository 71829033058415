import React from 'react'
import Fade from 'react-reveal/Fade'
import {graphql} from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image'

const Image = ({ desktopImage, mobileImage }) => {
  return (
    <section className='image'>
     <Fade distance='40px' bottom>
       <div className='image__inner'>
         { desktopImage && <div className='image__img--desktop'><GatsbyImage image={desktopImage.gatsbyImageData} alt='Associated Concepts' /></div> }
         { mobileImage && <div className='image__img--mobile'><GatsbyImage image={mobileImage.gatsbyImageData} alt='Associated Concepts' /></div> }
       </div>
     </Fade>
    </section>
  )
}

export default Image

export const query = graphql`
  fragment ImageProps on DatoCmsImage {
    ... on DatoCmsImage {
      desktopImage {
        gatsbyImageData (width: 2400)
      }
      mobileImage {
        gatsbyImageData (width: 1200)
      }
    }
  }
`