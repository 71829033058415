import React, {Component} from 'react'
import Link from '../utils/link'
import {navigate} from 'gatsby'
import {Logo} from './icons'
import StructuredText from "./custom-structured-text-renderer";

class Header extends Component {

  state = {
    offCanvas: false,
    scrolledPastLanding: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  _hideOffCanvas = () => {
    this.setState({offCanvas: false})
  }

  _toggleOffCanvas = () => {
    document.querySelector('body').classList.toggle('offcanvas-active', !this.state.offCanvas)
    this.setState({offCanvas: !this.state.offCanvas})
  }

  handleScroll = () => {
    const landingSectionHeight = document.querySelector('.landing')?.clientHeight - 100;
    const scrolledPastLanding = window.scrollY > landingSectionHeight;

    this.setState({scrolledPastLanding});
  };

  render() {
    let {offCanvas, scrolledPastLanding} = this.state
    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={`header ${scrolledPastLanding ? 'header--scrolled' : ''}`}>
          <div className='header__inner'>
            <div className='header__menu'>
              <button onClick={() => this._toggleOffCanvas()}>Menu</button>
              <ul>
                <li><Link to='/menu/' {...props}>Menu</Link></li>
                <li><Link to='/private-dining/' {...props}>Private Dining</Link></li>
                <li><Link to='/reservations/' {...props}>Reservations</Link></li>
                <li><Link to='/whats-on/' {...props}>What's On</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li><Link to='https://giftcards.nowbookit.com/cards/card-selection?accountid=c841d62d-257b-40e7-8a6b-1fed4b87f86e&venueid=11435&theme=dark&accent=215,15,0' {...props}>Vouchers</Link></li>
              </ul>
            </div>

            <div className='header__logo'>
              <Link to='/'>
                <Logo/>
              </Link>
            </div>
          </div>
        </header>

        <div className={`off-canvas ${offCanvas ? 'active' : ''}`}>
          <div className='off-canvas__wrapper'>
            <div className='off-canvas__menu'>
              <button onClick={() => this._hideOffCanvas()}>Close</button>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/menu/' {...props}>Menu</Link></li>
                <li><Link to='/private-dining/' {...props}>Private Dining</Link></li>
                <li><Link to='/reservations/' {...props}>Reservations</Link></li>
                <li><Link to='/whats-on/' {...props}>What's On</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li><Link to='https://giftcards.nowbookit.com/cards/card-selection?accountid=c841d62d-257b-40e7-8a6b-1fed4b87f86e&venueid=11435&theme=dark&accent=215,15,0' {...props}>Vouchers</Link></li>
              </ul>
            </div>

            <div className='off-canvas__address'>
              <p>151 Toorak Road South Yarra VIC 3141</p>
              <Link to='tel:03 9000 8937'>03 9000 8937</Link>
              <Link to='mailto:eat@tokenrestaurant.com.au'>eat@tokenrestaurant.com.au</Link>
              <Link to='https://www.instagram.com/token_au' target='_blank' rel='noopener noreferrer'>Instagram</Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
