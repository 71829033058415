import React from 'react'
import Fade from 'react-reveal/Fade'
import {graphql} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image'

const Image = ({images}) => {
  console.log(images)
  return (
    <section className='gallery'>
      <Fade distance='40px' bottom>
        <div className='gallery__inner'>
          <div className='gallery__items'>
            {
              images.length > 0 && images.map((el, i) => (
                <div className='gallery__item' key={i}><GatsbyImage image={el.gatsbyImageData} alt='Token'/></div>
              ))
            }
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default Image

export const query = graphql`
fragment GalleryProps on DatoCmsGallery {
  ... on DatoCmsGallery {
    id
    images {
      gatsbyImageData(width: 1200)
    }
  }
}
`