import React from "react";
import {graphql} from "gatsby";
import StructuredText from './custom-structured-text-renderer';

const Content = ({richContent}) => {
  return (
    <section className='content'>
      <div className='content__inner'>
        {richContent?.value && <StructuredText data={richContent}/>}
      </div>
    </section>
  )
}

export default Content

export const query = graphql`
  fragment ContentProps on DatoCmsContent {
    ... on DatoCmsContent {
        id
          richContent {
            value
          }
      }
  }
`