import React, { useState, useEffect } from 'react';
import PopupImage from '../assets/images/popup.jpg';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if the popup has been shown before
    // const popupShown = localStorage.getItem('popupShown');
    // if (!popupShown) {
      // Set a timeout to open the popup after 4 seconds
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);

      // Cleanup the timeout if the component unmounts before the timer completes
    //   return () => clearTimeout(timer);
    // }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div className='popup'>
        <div className='popup__wrapper'>
          <button onClick={handleClose}>Close</button>
          <a target='_blank' rel='noopener noreferrer' href='https://www.sevenrooms.com/experiences/token/endless-flow-5611950098317312'>
            <img
              src={PopupImage}
              alt="Popup"
            />
          </a>
        </div>
      </div>
    )
  );
};

export default Popup;